import ReshopperButton from "@components/web/Button";
import { useLocalization } from "@hooks/localization";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import { useEffect, useState } from "react";
import styles from "./GiftCardAndButton.module.scss";

export default function GiftCardAndButton(props: {
	amountInHundreds: number;
	buttonText?: string;
	onClick?: () => void;
	giftCardStyle?: React.CSSProperties;
}) {
	const [show, setShow] = useState(false);
	const t = useLocalization();

	useEffect(() => { setShow(true); }, []);

	const getText = () => {
		if (props.amountInHundreds > 0) {
			return <><span>{props.amountInHundreds / 100}</span><span className={styles.amountCurrency}>DKK</span></>
		}
		return t("GiftCard_Text_Custom");
	}

	return <>
		<Fade in={show} timeout={{ enter: 600 }}>
			<Box className={styles.giftCardContainer}>
				<Box className={styles.giftCard} style={props.giftCardStyle || {}}>
					<Box className={styles.giftCardText}>
						<Typography variant="h3" className={styles.giftCardTitle}>
							{t("GiftCard")}
						</Typography>
						<Typography className={styles.giftCardAmount}>
							{getText()}
						</Typography>
					</Box>
				</Box>
				{props.buttonText &&
					<Box textAlign="center">
						<span className={styles.buttonWrapper}>
							<ReshopperButton
								variant="contained"
								size="large"
								color="primary"
								style={{
									marginBottom: 75
								}}
								onClick={props.onClick}
							>
								{props.buttonText}
							</ReshopperButton>
						</span>
					</Box>
				}
			</Box>
		</Fade>
	</>
}