import { webClient } from "@api/web/WebClient";
import CollapseBox from "@components/web/CollapseBox";
import { withWebLayout } from "@components/web/Decorators";
import FooterWithPaymentIcons from "@components/web/FooterWithPaymentIcons";
import BuyingGiftCardDialog from "@components/web/gift-card/BuyingGiftCardDialog";
import GiftCardAndButton from "@components/web/gift-card/GiftCardAndButton";
import { FrontPageLayout } from "@components/web/layouts/FrontPageLayout";
import { useAuthenticatedUser } from "@hooks/authentication";
import { useLocalization } from "@hooks/localization";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import i18n from "@utils/i18n";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Trans } from "react-i18next";
import LogoWhiteIcon from "../images/newFrontpage/logo/logo-vertical-white-dark-text.svg";
import styles from "./index.module.scss";

export default withWebLayout(function GiftCardPage() {
	const arGiftCardAmountsInHundreds = [10000, 25000, 50000, 75000, 100000];
	const [currentlySelectedGiftCardAmount, setCurrentlySelectedGiftCardAmount] = useState<number | undefined>(undefined);
	const t = useLocalization();
	const authenticatedUser = useAuthenticatedUser();

	useEffect(() => {

		const getUserLanguage = async () => {
			try {
				const languageResponse = await webClient().webUsersLanguageGet();

				const language = languageResponse.language;
				if (i18n.language !== language) {
					i18n.changeLanguage(language);
				}

			} catch (error) {
				console.error(error);
			}
		};
		getUserLanguage();
	}, []);

	return <GiftCardPageTemplate>
		<Box className={styles.pxSection}>
			<Typography className={styles.textContainer}>{t("GiftCard_Text")}</Typography>
			<Box className={styles.allGiftCardsContainer}>
				{arGiftCardAmountsInHundreds.map((giftcardAmount, index) =>
					<GiftCardAndButton
						key={index}
						amountInHundreds={giftcardAmount}
						buttonText={t("GiftCard_Btn_Buy")}
						onClick={() => setCurrentlySelectedGiftCardAmount(giftcardAmount)}
					/>
				)}
				<GiftCardAndButton
					amountInHundreds={0}
					buttonText={t("GiftCard_Btn_CustomAmount")}
					onClick={() => setCurrentlySelectedGiftCardAmount(0)}
				/>
			</Box>
		</Box>

		<Box className={`${styles.faqContainer} ${styles.pxSection}`}>
			<Box className={styles.faqTextContainer}>
				<Typography variant="h1" className={`${styles.likeH2} ${styles.fontAvenir}`}>
					{t("GiftCard_FAQ_Title")}
				</Typography>
				<Typography>
					<Trans i18nKey="GiftCard_FAQ_Text" />
				</Typography>
			</Box>

			<GiftCardFAQ />
		</Box>

		<FooterWithPaymentIcons style={{ backgroundColor: "#fff" }} />

		<BuyingGiftCardDialog
			isDialogOpen={currentlySelectedGiftCardAmount !== undefined}
			onClose={() => setCurrentlySelectedGiftCardAmount(undefined)}
			giftCardAmount={currentlySelectedGiftCardAmount}
			authenticatedUser={authenticatedUser}
		/>

	</GiftCardPageTemplate>
});


function GiftCardPageTemplate(props: {
	children: React.ReactNode
}): JSX.Element {
	const t = useLocalization();
	return <>
		<FrontPageLayout removeBlueBodyBackground>
			<Helmet
				title={"Reshopper gavekort"}>
				<meta name="description" content={"Med et gavekort fra Reshopper kan du give en meningsfuld gave, der glæder både børnefamilien, miljøet og pengepungen"} />
				<meta name="robots" content="index, follow" />
			</Helmet>
			<Container className={styles.giftCardPage}>
				<Box className={`${styles.giftCardHeader} ${styles.pxSection}`}>
					<img alt="Reshopper" src={LogoWhiteIcon} width="270" height="197" />
				</Box>
				{props.children}
			</Container>
		</FrontPageLayout>
	</>
};

function GiftCardFAQ(): JSX.Element {
	const t = useLocalization();

	return <Box>
		<CollapseBox
			title={t("GiftCard_FAQ_WhereToBuyGiftCard_Title")}
			text={<Trans
				i18nKey="GiftCard_FAQ_WhereToBuyGiftCard_Text"
				values={{
					link: "/#map"
				}}
				components={{ a: <a /> }} />} />
		<CollapseBox
			title={t("GiftCard_FAQ_HowLongIsGiftCardValidFor_Title")}
			text={t("GiftCard_FAQ_HowLongIsGiftCardValidFor_Text")} />
		<CollapseBox
			title={t("GiftCard_FAQ_HowIsGiftCardReceived_Title")}
			text={<Trans i18nKey="GiftCard_FAQ_HowIsGiftCardReceived_Text" />} />
		<CollapseBox
			title={t("GiftCard_FAQ_WhereCanGiftCardBeUsed_Title")}
			text={<Trans i18nKey="GiftCard_FAQ_WhereCanGiftCardBeUsed_Text" />} />
		<CollapseBox
			title={t("GiftCard_FAQ_NeedProfileToBuy_Title")}
			text={t("GiftCard_FAQ_NeedProfileToBuy_Text")} />
		<CollapseBox
			title={t("GiftCard_FAQ_TermsOfUse_Title")}
			text={<Trans
				i18nKey="GiftCard_FAQ_TermsOfUse_Text"
				values={{
					link1: "https://reshopper.com/dk/brugervilkaar",
					link2: "https://reshopper.com/handelsbetingelser"
				}}
				components={{ a: <a /> }} />} />
		<CollapseBox
			title={t("GiftCard_FAQ_NoEmailReceived_Title")}
			text={<Trans
				i18nKey="GiftCard_FAQ_NoEmailReceived_Text"
				values={{
					link: "mailto:support@reshopper.com?subject=Køb af gavekort"
				}}
				components={{ a: <a /> }} />}
		/>
	</Box>
}
