import { webClient } from "@api/web/WebClient";
import PaymentButton from "@components/web/financial/PaymentButton";
import GiftCardAndButton from "@components/web/gift-card/GiftCardAndButton";
import { ReshopperInput } from "@components/web/Input";
import LoginDialog from "@components/web/LoginDialog";
import { useLocalization } from "@hooks/localization";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { WebUsersAuthenticatedGetUserResponse } from "@reshopper/web-client";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./BuyingGiftCardDialog.module.scss";

interface FormValues {
	giftCardAmount: number | string,
	firstName: string,
	lastName: string,
	email: string,
	acceptTerms: boolean,
	formValid: boolean
}

export default function BuyingGiftCardDialog(props: {
	isDialogOpen: boolean;
	onClose: () => void;
	title?: string;
	giftCardAmount: number | undefined;
	authenticatedUser: WebUsersAuthenticatedGetUserResponse | null | undefined;
}) {
	const t = useLocalization();
	const [isOpenLoginMenu, setIsOpenLoginMenu] = useState(false);
	const emailValidationRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	const minVoucherAmountInHundreds = 10000;
	const maxVoucherAmountInHundreds = 250000;

	const [formState, setFormState] = useState<FormValues>({
		giftCardAmount: "",
		firstName: "",
		lastName: "",
		email: "",
		acceptTerms: false,
		formValid: false
	});

	const isFormValid = useMemo(
		() => {
			if (
				formState.giftCardAmount >= minVoucherAmountInHundreds &&
				formState.giftCardAmount <= maxVoucherAmountInHundreds &&
				formState.firstName != "" &&
				formState.lastName != "" &&
				emailValidationRegex.test(formState.email) &&
				formState.acceptTerms) {
				return true;
			}
			return false;
		}, [formState]);


	useEffect(() => {
		if (props.giftCardAmount !== undefined) {
			setFormState({
				...formState,
				firstName: props.authenticatedUser?.firstName ?? "",
				lastName: props.authenticatedUser?.lastName ?? "",
				email: props.authenticatedUser?.email ?? "",
				giftCardAmount: props.giftCardAmount === 0 ? "" : props.giftCardAmount,
				acceptTerms: false
			});
		}

	}, [props.giftCardAmount, props.authenticatedUser]);


	const handleChange = (field: keyof typeof formState) =>
		(e: React.ChangeEvent<HTMLInputElement>) => {

			let value = e.target.value;
			if (field === "giftCardAmount") {
				value = value === "" ? "" : String(Math.round(+value.replace(/[^0-9]*/g,"")) * 100);
			}

			setFormState({
				...formState,
				[field]: value
			});
		};

	const handleCheckboxChange = (field: keyof typeof formState) =>
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setFormState({
				...formState,
				[field]: e.target.checked
			});
		};


	const validateEmailBeforePayment = async () => {
		try {
			const response =
				await webClient().webSendGridEmailValidationGet({
					body: {
						email: formState.email,
						source: "GiftCardPurchase"
					}
				});
			return response.emailValidationResult;
		}
		catch (ex) {
			console.log(ex);
			return undefined;
		}
	}

	return <>
		<Dialog
			className={styles.buyingGiftCardDialog}
			open={props.isDialogOpen}
			onClose={() => props.onClose()}
		>
			<DialogContent>
				<GiftCardAndButton
					amountInHundreds={+formState.giftCardAmount ?? 0}
					giftCardStyle={{ width: "100%" }} />

				<Typography variant="h2" className={styles.dialogTitle}>{t("GiftCard_Form_Title")}</Typography>
				<Typography className={styles.textInfo}>{t("GiftCard_Form_Text")} <br /><br /> {t("GiftCard_Form_Text_NoLogin")}</Typography>
				{!props.authenticatedUser &&
					<Typography
						className={styles.alreadyUserText}
						onClick={() => setIsOpenLoginMenu(true)}>
						{t("GiftCard_Form_AlreadyUser")}
					</Typography>
				}

				<Box component="form" className={styles.formContainer}>
					{props.giftCardAmount === 0 &&
						<ReshopperInput
							value={formState.giftCardAmount != "" ? +formState.giftCardAmount / 100 : formState.giftCardAmount}
							onChange={handleChange('giftCardAmount')}
							className={`${styles.textField} ${styles.amountInput}`}
							label={t("GiftCard_Form_EnterAnAmount_Label")}
							type="number"
							error={formState.giftCardAmount !== "" && (formState.giftCardAmount > maxVoucherAmountInHundreds || formState.giftCardAmount < minVoucherAmountInHundreds)}
							helperText={t("GiftCard_Form_EnterAnAmount_HelperText")}
							inputProps={{
								min: 100,
								max: 2500,
								step: 1,
							}}
							onKeyDown={(e) => {
								if(e.key === 'e' || e.key === "," || e.key === "."){
									e.preventDefault() 
								}
							}}
							autoFocus
							required />
					}

					<ReshopperInput
						value={formState.firstName}
						onChange={handleChange('firstName')}
						className={styles.textField}
						label={t("GiftCard_Form_FirstName_Label")}
						type="text"
						InputProps={{ readOnly: !!props.authenticatedUser }}
						disabled={!!props.authenticatedUser}
						required />

					<ReshopperInput
						value={formState.lastName}
						onChange={handleChange('lastName')}
						className={styles.textField}
						label={t("GiftCard_Form_LastName_Label")}
						type="text"
						InputProps={{ readOnly: !!props.authenticatedUser }}
						disabled={!!props.authenticatedUser}
						required />

					<ReshopperInput
						value={formState.email}
						onChange={handleChange('email')}
						className={styles.textField}
						label={t("LogIn_Email")}
						type="email"
						InputProps={{ readOnly: !!props.authenticatedUser }}
						disabled={!!props.authenticatedUser}
						error={formState.email != "" && !emailValidationRegex.test(formState.email)}
						helperText={t("GiftCard_Form_Email_HelperText")}
						required />

					<FormControlLabel
						className={styles.checkBox}
						label={<>
							<Typography className={styles.preventSelect}>
								<span dangerouslySetInnerHTML={{
									__html: t("GiftCard_Form_Terms_Label", { link: "/handelsbetingelser" })
								}} />
							</Typography>
						</>}
						control={
							<Checkbox
								checked={formState.acceptTerms ?? false}
								onChange={handleCheckboxChange('acceptTerms')}
							/>}
					/>
				</Box>
				<PaymentButton
					variant="primary"
					uniqueId={""}
					sessionType="vouchers"
					disabled={!isFormValid}
					giftCardInfo={{
						vouchersAmountsInHundreds: [+formState.giftCardAmount],
						buyerDetails: !props.authenticatedUser ?
							{
								email: formState.email,
								firstName: formState.firstName,
								lastName: formState.lastName
							} :
							undefined
					}}
					style={{ marginTop: 0 }}
					onSendGridEmailValidation={props.authenticatedUser !== null ? undefined : () => validateEmailBeforePayment()}
				>
					{t("GiftCard_Form_Btn")}
				</PaymentButton>
			</DialogContent>
		</Dialog>

		<LoginDialog open={isOpenLoginMenu} onClosed={() => setIsOpenLoginMenu(false)} />
	</>
}